import React from 'react';
import { Field, Form, useFormikContext } from 'formik';
import type { PageProps } from 'gatsby';
import type { AppState } from '@auth0/auth0-react';
import { AccountFormValues } from '@common/types';
import FieldError from './fields/field-error';
import Button from './button';
import useSubscriptionContext from '../hooks/subscription-context';
import appToast from '../utils/toast';
import * as styles from './account-form.module.scss';

export enum AccountPageStateAction {
  EditTeamId = 'EDIT_TEAM_ID',
}

export interface AccountPageState extends AppState {
  action?: AccountPageStateAction;
}

const AccountForm = ({
  location,
}: Pick<PageProps<unknown, unknown, AccountPageState>, 'location'>) => {
  const { dirty, touched, errors, isValid, submitForm } =
    useFormikContext<AccountFormValues>();
  const { currentPlan, isActive } = useSubscriptionContext();

  /**
   * Focuses the teamId input on page render if the corresponding state value
   * has been passed
   */
  const handleFocusOnTeamId = (field?: HTMLInputElement) => {
    if (!field || location.state?.action !== AccountPageStateAction.EditTeamId)
      return;

    field.focus();
  };

  const isTeamAdmin = isActive && currentPlan === 'TEAM';

  const handleSubmitFormClick = async () => {
    if (!isValid) {
      return appToast.error('Please correct invalid fields and try again.');
    }

    try {
      await submitForm();
    } catch (error) {
      appToast.error('Something went wrong.');
    }
  };

  // TODO: Account delete action
  const handleDeleteAccountClick = () => console.log('deleting account');

  return (
    <Form className={styles.AccountForm}>
      <div className={styles.AccountForm__body}>
        <h2>Details</h2>

        <label htmlFor="email">Email address</label>
        <Field
          type="email"
          name="email"
          id="email"
          required
          aria-invalid={!!errors.email}
        />
        {touched && errors.email && <FieldError>{errors.email}</FieldError>}

        {isTeamAdmin && (
          <>
            <label htmlFor="teamId">Team ID</label>
            <small className="field-description">
              Edit your team URL.{' '}
              <a
                href="https://mirosign.com/documentation/what-is-the-mirosign-team-id/"
                target="_blank"
                rel="noreferrer"
              >
                More information
              </a>
            </small>
            <Field
              innerRef={handleFocusOnTeamId}
              type="text"
              name="teamId"
              id="teamId"
              required
              aria-invalid={!!errors.teamId}
            />
            {touched && errors.teamId && (
              <FieldError>{errors.teamId}</FieldError>
            )}

            <label htmlFor="teamName">Team name</label>
            <small className="field-description">
              This is your team generator page title.{' '}
              <a
                href="https://mirosign.com/documentation/what-is-the-mirosign-team-name/"
                target="_blank"
                rel="noreferrer"
              >
                More information
              </a>
            </small>
            <Field
              type="text"
              name="teamName"
              id="teamName"
              required
              aria-invalid={!!errors.teamName}
            />
            {touched && errors.teamName && (
              <FieldError>{errors.teamName}</FieldError>
            )}
          </>
        )}
      </div>

      <footer className={styles.AccountForm__actions}>
        {dirty && (
          <Button isBlock onClick={handleSubmitFormClick}>
            Save changes
          </Button>
        )}

        <Button
          isBlock
          theme="secondary"
          isDisabled={isActive}
          onClick={handleDeleteAccountClick}
        >
          Delete account
        </Button>
      </footer>
    </Form>
  );
};

export default AccountForm;
